.authenticator {
  z-index: 2;
  font-size: 32px;
  line-height: 1.0556;
  font-weight: 100;
  letter-spacing: 0.023em;
  text-align: center;
  font-style: normal;
  font-family: "Myriad Set Pro", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif;
}

.error-text {
  margin-left: -1px;
  font-weight: 400;
}

.scan {
  z-index: 2;
  font-size: 13px;
  line-height: 1.0556;
  text-align: center;
  font-weight: 700;
  letter-spacing: 0.023em;
  font-style: normal;
  margin-top: 6px;
  color: #fff;
}

.verify {
  z-index: 2;
  font-size: 13px;
  line-height: 1.0556;
  font-weight: 200;
  letter-spacing: 0.023em;
  font-style: normal;
  font-family: "Myriad Set Pro", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif;
  margin-top: 20px;
}

.authText {
  z-index: 2;
  font-size: 14px;
  line-height: 1.2556;
  /* text-align: center; */
  font-weight: 400;
  letter-spacing: 0.023em;
  font-style: normal;
  margin-top: 6px;
  color: #fff;
}

.authEmail {
  text-decoration: underline;
  color: #007bff;
  font-weight: 400;
}

@media (max-width: 1080px) {
  .left {
    margin-top: 3rem;
  }
}
