:root {
  --black: #000;
  --white: #ffffff;
  --primary: #eb9783;
  --light: #de47240d;
  --dark: #de4724;
  --bs-accordion-active-bg: var(--white);
  --chartbg: #f9f9f9;
}
* {
  font-family: "pangram";
}
.verticle_gap_in_components {
  row-gap: 20px;
  display: flex;
  flex-direction: column;
}
.gap-10 {
  gap: 10px;
}
.gradient-progress-block {
  height: 350px;
}

.process-bar-wappers.process2 h3.score-name {
  font-size: 24px;
  line-height: 30px;
}

.process-bar-wappers.process2 ul.score-textgray {
  padding: 0;
  list-style: none;
  margin: 0;
}
.main_dashboard_block {
  display: block;
}

.dashboard_wrapper {
  display: flex;
}
.green {
  color: green;
}
.red {
  color: red;
}

/* ----------------------- Table header=---------------------  */

.searchbar_table .input-group-text {
  background-color: transparent;
  border-right-color: transparent;
  padding-inline-end: 0;
}
.searchbar_table .input-group-text:focus {
  border-right-color: transparent !important;
}
.searchbar_table .form-control:focus {
  border-left-color: transparent !important;
}
.searchbar_table .form-control {
  border-left-color: transparent;
}
.searchbar_table {
  display: flex;
  justify-content: end;
  gap: 10px;
  /* margin-bottom: 20px; */
}
.rented-form-selected {
  width: auto !important;
}
.searchbar_table button {
  width: 40px;
  height: 40px;
  background: white;
  border: 1px solid var(--dark);
  border-radius: 12px;
  margin-left: 18px;
}
.searchbar_table button svg {
  width: 100%;
  height: 100%;
}
.input_table_header {
  height: 46px;
  width: 296px;
  opacity: 0.5;
  border-radius: 12px;
}
.header_table .form-control {
  border-left: transparent !important;
}
.header_table .input-group-text {
  border-right: transparent !important;
  background-color: #ffffff !important;
}
.header_table h1 {
  font-family: "Pangram";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
  color: #212121;
  margin-bottom: 30px;
}
.border-pd {
  background: #fff;
  border: 0.6px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 34px 1px #00000008;
  height: 100%;
  padding: 18px;
}

.score-summary {
}

.border-pd h4 {
  text-align: center;
  color: #121212;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
  text-align: center;
}

.border-pd .summary-checkes {
  display: grid;
  gap: 10px;
}

.border-pd .summary-checkes label.rounded-check.summaryinfobar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
}

.summarydropdum {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  width: auto;
}
.primaryaccordion-body .Notifications-wapper.opacity-wapper p.d-flex.gap-2 {
}

.primaryaccordion-body .Notifications-wapper.opacity-wapper p.d-flex.gap-2 a {
  padding: 0 !important;
}

.primaryaccordion-body .Notifications-wapper.opacity-wapper h4 {
  font-size: 20px;
}
button.iconinfo {
  background: #0000;
  border: none;
  color: #fd7131 !important;
  font-size: 20px !important;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
}

ul.tooltip {
  background: #fff;
  border: 1px solid #ddd9;
  border-radius: 14px;
  box-shadow: 0 8px 24px 2px #0000000f;
  display: block;
  opacity: 0;
  padding: 12px;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 200px;
  z-index: 2;
  list-style: none;
}

.scrorebardrop {
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  display: inline-flex;
  position: relative;
}

.scrorebardrop:hover {
}

.scrorebardrop:hover ul.tooltip {
  opacity: 1;
  visibility: visible;
  left: 20px;
  top: 20px;
}

ul.tooltip li {
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 5px 0;
}

.summarydropdum:hover ul.tooltip {
  opacity: 1;
  visibility: visible;
  left: 30px;
  top: 20px;
}
.primaryaccordion-body {
  border: none !important;
  padding: 20px !important;
}

.primaryaccordion-body h4.accordion-title {
  color: var(--Dark-green, #253837);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}

.transaction-rental td {
  white-space: nowrap;
}
.address-content p {
  white-space: wrap;
  min-width: 300px;
}

.hstack {
  grid-gap: 18px !important;
  display: grid;
  gap: 18px !important;
  grid-template-columns: 6fr 1fr;
}

.hstack select.form-select.form-select-lg {
  background: #fff !important;
  border: 1px solid #7f7f7f !important;
  border: 1px solid var(--Grey, #7f7f7f) !important;
  border-radius: 12px !important;
  color: #7f7f7f !important;
  color: var(--Grey, #7f7f7f) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  height: 100%;
  line-height: normal !important;
  padding: 12px !important;
}

.hstack .btn-primary {
  background: var(--Light-Green, #a3eb64) !important;
  border: 1px solid var(--Light-Green, #a3eb64) !important;
  border-radius: 12px !important;
  color: var(--Dark-green, #253837) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 14px !important;
}
.Score-content-list h3 {
  font-size: 16px;
  color: #121212;
}

.Score-content-list h4 {
  color: #9a9a9a !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-bottom: 10px !important;
  text-align: left !important;
}

.email_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
body .primaryaccordion-header .accordion-button {
  margin: 0;
  align-items: center;
  background: #e3e3e3 !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  color: #223635;
  display: flex;
  font-family: Google Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  gap: 12px;
  justify-content: flex-start;
  line-height: normal;
  padding: 20px 50px 20px 20px;
  height: auto;
}

.primaryaccordion-header .accordion-button span.maintitle {
  align-items: center;
  color: #223635;
  display: inline-flex;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  gap: 4px;
  line-height: normal;
}

.primaryaccordion-header .accordion-button::before {
  right: 20px;
  position: absolute;
}

.primaryaccordion-header .accordion-button span.accordion_score {
  background: #253837;
  background: var(--Dark-green, #253837);
  border-radius: 8px;
  color: #a3eb64;
  color: var(--Light-Green, #a3eb64);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 9px 60px;
  text-transform: capitalize;
}

.primaryaccordion {
  display: grid;
  gap: 10px;
}

.primaryaccordion-header .accordion-button span.maintitle .infodorpdown {
  padding: 0;
  margin: 0;
  list-style: none;
}

.primaryaccordion-header
  .accordion-button
  span.maintitle
  .infodorpdown
  li
  .guarantor_tooltip {
  background: #0000;
  border: none;
  color: #fd7131;
  font-size: 16px;
  padding: 0;
}

.primaryaccordion-header
  .accordion-button
  span.maintitle
  .infodorpdown
  ul.tooltip {
  background: #fff;
  border: 1px solid #ddd9;
  border-radius: 14px;
  box-shadow: 0 8px 24px 2px #0000000f;
  display: block;
  opacity: 0;
  padding: 12px;
  position: absolute;
  visibility: hidden;
  width: 200px;
  z-index: 2;
  top: 20px;
  left: 30px;
  list-style: none;
}

.primaryaccordion-header
  .accordion-button
  span.maintitle
  .infodorpdown:hover
  ul.tooltip {
  opacity: 1;
  visibility: visible;
  display: block;
}

.primaryaccordion-header
  .accordion-button
  span.maintitle
  .infodorpdown
  ul.tooltip
  li {
  color: #728685;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 5px 0;
}
.modal-uploadpdf .modal-body {
  padding: 20px 20px 20px 20px !important;
}

.modal-uploadpdf .modal-body .formcontrolbar {
  display: grid;
  gap: 20px;
  padding: 20px;
  background: #f7f7f7;
  margin-bottom: 20px;
  padding: 14px;
  width: 100%;
}
.hstack.gap-2 {
  grid-gap: 18px !important;
  display: grid;
  gap: 18px !important;
  grid-template-columns: 6fr 1fr;
}
/* ------------------- Sidebar css---------------- */
.sidebarhead ul li {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.sidebarhead ul li a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  height: 50px;
  padding: 14px 10px 14px 30px;
}

.sidebarhead ul li a.active {
  background-color: #a3eb64;
  color: #000;
  /* border-left: 4px solid var(--dark); */
}
/* .sidebarhead .accordion-button:focus {
  background-color: var(--light) !important;
  border-left: 4px solid var(--dark) !important;
} */
/* .sidebarhead .accordion-item .collapsed {
  background-color: #fff !important;
}
.sidebarhead .accordion-item .accordion-button {
  background-color: var(--light) !important;
  border-left: 4px solid var(--dark);
} */
.sidebarhead .accordion-item .active button {
  background-color: #a3ec64 !important;
  color: #000 !important;
}
.sidebarhead .accordion-item .active button::after {
  filter: brightness(0) saturate(100%) invert(0%) sepia(92%) saturate(0%)
    hue-rotate(221deg) brightness(111%) contrast(100%);
}
.sidebarhead ul {
  padding-left: 0;
  justify-content: center;
}
.form-select {
  height: 46px;
  padding: 0 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #e6e9ed !important;
  border-radius: 0.375rem !important;
}
.form-select:focus {
  box-shadow: none !important;
}
.create-admin-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  /* background: var(--light); */
  border: 1px solid #ddd;
  width: 100vw;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 10px;
}
.create-admin-div button {
  background-color: #a3ec64;
  border-radius: 4px;
  color: #000;
  padding: 10px 20px;
  border-color: #a3ec64;
  box-shadow: none;
  border: 1px solid #a3eb64;
  margin-top: 10px;
}
svg.eye-icons {
  position: absolute;
  top: 40%;
  right: 30px;
}
button.action-btn {
  background: none;
  border: 1px red solid;
  border-radius: 5px;
}

.dashboardsidebar {
  position: sticky;
  top: 0;
  left: 0;
  background: #253937;
  height: 100vh;
  border-right: 1px solid #b2b2b25e;
  max-width: 16%;
  transition: all 0.4s ease-in-out;
}
.logobar img {
  padding: 20px 10px 20px;
  display: flex;
  margin: auto auto auto 6px;
}
.accordion-body a {
  padding: 14px 10px 14px 66px !important;
}
.sidebarhead ul li a svg {
  margin: 0 0.5rem;
  width: 20px;
  height: 20px;
  flex: 0 0 10%;
}
.accordion-item button svg {
  margin: 0 0.5rem;
}
.accordion-item {
  border: none !important;
}
.accordion-item button {
  font-size: 16px;
  font-family: "pangram";
  color: var(--black);
  padding: 0;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  height: 50px;
  padding: 14px 10px 14px 0px;
}
/* .accordion-collapse.show + .accordion-button::after {
  filter: brightness(0) saturate(100%) invert(98%) sepia(98%) saturate(10%)
    hue-rotate(172deg) brightness(102%) contrast(100%);
} */
.sidebarhead .accordion-button::after {
  margin-left: auto;
  content: "";
  background-image: url("../../public/images/arrow.png") !important;
  background-repeat: no-repeat;
  opacity: 1;
  /* width: 12px;  */
  height: 8px;
  margin-inline-end: 6px;
  background-size: contain;
}

.sidebarhead .accordion-button::after {
  display: block !important;
}
.sidebarhead .accordion-button {
  padding-left: 6px;
}
.sidebarhead .accordion-button::before {
  display: none;
}
.accordion-button::before {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: 10px;
  content: "";
  opacity: 0.5;
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  /* background-size: var(--bs-accordion-btn-icon-width); */
  transition: var(--bs-accordion-btn-icon-transition);
}
.accordion-button::after {
  display: none !important;
}
.accordion-body .active {
  background-color: #a3ec64 !important;
  color: #000 !important;
  border-radius: 6px;
}

.card-listing .card {
  position: relative;
  background: #fff !important;
  border: 0.6px solid #ddd !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 34px 1px #00000008 !important;
}

.card-listing .card {
}

.card-listing .card .videoframe {
  height: 230px;
}

.card-listing .card .videoframe iframe {
  height: 100%;
  overflow: hidden;
  padding: 0 !important;
  width: 100%;
}
.card-listing .card .card-body {
  padding: 12px;
}

.card-listing .card .card-body .listing-head .head-block {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.card-listing .card .card-body .listing-head .head-block .leftbartext {
  flex: 0 0 85%;
}

.card-listing .card .card-body .listing-head .head-block .leftbartext h5 {
  color: #121212;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
  text-transform: capitalize;
}

.card-listing .card .card-body .listing-foot .iconssgroups {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.card-listing
  .card
  .card-body
  .listing-foot
  .iconssgroups
  span.del-icon.deleteicon {
  border-color: red !important;
}
.card-listing
  .card
  .card-body
  .listing-foot
  .iconssgroups
  span.del-icon.deleteicon:hover {
  border-color: rgb(160, 1, 1) !important;
}
.card-listing .card .card-body .listing-foot .iconssgroups span.del-icon {
  align-items: center;
  border: 0.6px solid #a3eb64 !important;
  border-radius: 50%;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 38px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.card-listing .card .card-body .listing-foot .iconssgroups span.del-icon:hover {
  border-color: #6aad30 !important;
}
.card-listing .card .card-body .listing-head .head-block .leftbartext p {
  color: var(--Grey, #7f7f7f);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-listing .card .card-body .listing-foot {
  align-items: center;
  /* display: flex; */
  justify-content: space-between;
}

.card-listing .card .card-body .listing-foot span {
  color: var(--Grey, #7f7f7f);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sidebarhead ul {
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
  background-color: #253837;
}
.sidebarhead ul li a {
  background: #253837;
  border-left: 4px solid transparent;
  padding: 14px 10px 14px 6px !important;
}
.accordion {
  --bs-accordion-bg: transparent !important;
  background-color: transparent !important;
  color: #fff;
}
.accordion-button {
  background-color: transparent !important;
  color: #fff !important;
}
.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--light) !important;
  outline: none !important;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("../../public/images/arrow.png") !important;
  /* filter: brightness(0) saturate(100%) invert(0%) sepia(92%) saturate(0%)
    hue-rotate(221deg) brightness(111%) contrast(100%); */
}
/* ----------- Table css------------- */
table {
  table-layout: inherit;
}

table thead {
  background: #a3ec64;
}
table thead tr th:first-child {
  border-radius: 8px 0 0 8px;
}
table thead tr th:last-child {
  border-radius: 0 8px 8px 0;
}
table tr a {
  text-decoration: none;
  color: var(--dark);
}
table tr th {
  border: none;
}
table thead tr th {
  font-weight: 500;
}
.table > :not(caption) > * > * {
  padding: 18px 20px !important;
}
.mainoutlet {
  width: 100%;
  /* margin-left: 270px; */
  padding: 20px 50px 18px;
}
.tableoutlet {
  width: 84%;
  /* margin-left: 250px; */
  padding: 20px 18px;
  height: 100%;
}
table tbody tr td .btn {
  font-size: 25px;
  /* width: 28px; */
  padding: 1px;
  line-height: 8px;
  height: 28px;
}

table tbody tr td a:hover {
  color: #de4724;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: var(--dark) !important;
  cursor: pointer !important;
}
/* ------------------- dashboard css--------------------- */

.text_data p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.text_data h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 8px;
}
.cardcontainer {
  margin-bottom: 10px;
}
.cardcontainer .card-body {
  padding: 2px 10px !important;
}
.mainoutlet .active {
  background-color: #a3ec64 !important;
  border-color: #a3ec64 !important;
  padding: 8px 22px;
}
/* ----------------------- User and Tenant Modal css --------------------------- */

.modal-header {
  border-bottom: none !important;
  padding: 24px 34px 12px !important;
}
.modal-body {
  padding: 0 34px !important;
}
.modal-title {
  font-size: 23px !important;
}
.user_details p {
  opacity: 0.5;
  font-size: 14px;
}
.user_details h5 {
  font-size: 16px !important;
  line-height: 8px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.row {
  padding: 12px 0;
}
.user_details:last-child {
  padding: 12px 0 24px !important;
}

/* ------------------------------ edit from modal ---------------------------  */

.edit_form_details form:last-child {
  padding: 15px 20px 30px;
}
.edit_form_details form button {
  background: var(--dark) !important;
}
.edit_form_details .form-control {
  height: 56px;
  border-radius: 12px !important;
}

/* -------------------- propertymodal------------------ */
.property_modal p {
  font-style: normal;
  font-family: "Pangram";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #cacaca;
}
.property_modal span {
  color: #000;
  font-family: "Pangram";
}
.property_modal a {
  color: var(--dark);
  font-family: "Pangram";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
}
.modal_h5 {
  font-weight: 500;
  font-size: 16px;
  font-family: "Pangram";
  line-height: 19px;
  padding: 10px 0;
}
.property_modal .row {
  padding: 0 !important;
}
.property_modal {
  border-bottom: 1px solid #c5c5c54f;
  margin-top: 10px;
}
.modal_body_star span {
  font-size: 35px;
  padding: 20px 0;
}
.modal_body_star {
  text-align: center;
}
.checked {
  color: gold;
}

/* ----------------------- charts css -------------------- */

.donut_chart .chart-wrapper canvas {
  max-height: 289px !important;
  width: auto !important;
}
.chart-wrapper {
  background: var(--chartbg);
  border-radius: 14px;
  padding: 33px;
}
.bar_chart .chart-wrapper {
  height: 355px !important;
}
.bar_chart .chart-wrapper canvas {
  max-height: 340px !important;
  width: auto !important;
}
.popover_buttons button {
  background: var(--white);
  border: none;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popover_buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.filter select {
  height: 38px;
  margin-bottom: 10px;
}
#apexchartsickbgti8k {
  height: 100% !important;
}
/* ----------------------- offers css ------------------ */

.main_wrapper_offers {
  height: 100%;
  width: 100%;
}
.main_wrapper_offers select {
  margin-bottom: 10px;
}
.image_picker {
  border: 1px dashed var(--dark);
  border-radius: 12px;
  display: flex;
  width: 200px;
  height: 200px;
  background: var(--light);
  justify-content: center;
  align-items: center;
  position: relative;
}
.offer_select button {
  margin: 0 !important;
  background-color: #ffffff !important;
  border: 1px solid rgba(186, 186, 186, 0.811) !important;
  color: #777777;
  height: 56px !important;
  margin-bottom: 10px !important;
}
.image_picker input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.offer_modal select {
  margin-bottom: 24px;
  color: hsla(0, 0%, 0%, 0.525);
}
.center_upload {
  color: #f09783;
  text-align: center;
  font-size: 14px;
}
.first_col form {
  max-width: 362px;
}
.second_col .stack_of_cards {
  max-width: 473px;
}

.first_col .form-control {
  height: 56px;
  border-radius: 12px;
}
.first_col button {
  width: 100%;
  background: var(--dark);
  margin: 15px 0;
  height: 52px;
  border: none;
  border-radius: 12px;
}
.first_col button:hover {
  background-color: #de4724;
}
.offer_card_edit button {
  background-color: var(--white);
  border: none;
  color: #de4724;
}
.card_image {
  max-width: 100px;
  height: 100px;
}
.offer_links a {
  text-decoration: none;
  color: var(--dark);
}
.offer_links a:hover {
  color: #e5401b;
}
.stack_of_cards .card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.stack_of_cards .card-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
}
.stack_of_cards .row {
  padding: 6px 0;
}
.offer_links {
  padding-right: 18px;
}
.offer_links p {
  font-weight: 400 !important;
}
.offer_modal .modal-dialog {
  max-width: 810px;
}
.image_shown {
  width: 220px;
  height: 181px;
}
.image_shown img {
  width: 165px;
}
.image_editor {
  position: relative;
  width: 181px;
}
.image_editor button {
  border: none;
  position: absolute;
  top: 9px;
  right: 26px;
  border-radius: 50px;
  color: var(--dark);
  padding: 4px 7px;
}
.offer_button button {
  background-color: var(--dark);
  border: none;
  height: 56px;
  border-radius: 12px;
}
.offer_button button:hover {
  background-color: #de4724;
}
.offer_modal_form input,
select {
  height: 56px;
  border-radius: 12px !important;
}
.input_modal_image label {
  background-color: var(--chartbg);
  color: var(--dark);
  padding: 6px 8px;
  font-family: sans-serif;
  border-radius: 1.2999999999999972rem;
  cursor: pointer;
  margin: 1rem 0;
}
.input_modal_image {
  position: absolute;
  top: 0;
  right: 30px;
}
.offer_modal_form label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.top_navbar {
  display: none;
}

/* ----- login----- */
.Authoutlet {
  width: 100%;
}
.Auth-wrapper a {
  color: white;
}
.right-contentbar h1 {
  font-size: 36px;
  color: white;
  opacity: 0.9;
  text-transform: capitalize;
  font-weight: 500;
}
.sidebar-panel {
  background-color: #253837;
}
.logobar {
  background-color: #253837;
}
.logobarr h1 {
  font-size: 33px;
  color: white;
  line-height: 39px;
  text-transform: uppercase;
  opacity: 0.8;
}
.Auth-wrapper {
  height: 100vh;
  padding: 65px 0;
  background-color: #223635;
}

.Auth-bar {
  width: 100%;
}

.Auth-bar .authbar {
  height: 100%;
}

.Auth-bar .authbar .container {
  height: 100%;
}

.Auth-bar .authbar .container .authbar-inner {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 130px 0;
}

.Auth-bar .authbar .container .authbar-inner .leftbar,
.Auth-bar .authbar .container .authbar-inner .rightbar {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 0px;
}

.Auth-bar .authbar .container .authbar-inner .leftbar h2 {
  margin: 0;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  text-transform: capitalize;
  color: white;
}

.Auth-bar .authbar .container .authbar-inner .rightbar {
  border-left: 1px solid rgb(255 255 255 / 20%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 100px;
}

.Auth-bar .authbar .container .authbar-inner .rightbar .right-contentbar {
  max-width: 362px;
  margin: 0 auto;
  text-align: center;
}
.Auth-bar .authbar .container .authbar-inner .rightbar .right-contentbar {
  max-width: 362px;
  margin: 0 auto;
  text-align: center;
}

.form-check-input[type="radio"] {
  border-color: #de4724;
  outline: none;
  box-shadow: none;
}

.form-check-input[type="radio"] {
  border-color: #de4724;
  outline: none;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #de4724;
  --bs-form-check-bg-image: none !important;
  border: 4px solid #ffffff;
  outline: 1px solid #de4724;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar {
  margin-bottom: 30px;
}

.inlinecheckbar .form-check {
  margin-right: 34px;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.inlinecheckbar .form-check.mr-0 {
  margin-right: 0;
}

.inlinecheckbar .form-check .form-check-label {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  color: white;
}

.inlinecheckbar .form-check-input[type="radio"] {
  background: transparent;
  border: 1px solid white;
  margin: 0 10px 0 0;
  width: 22px;
  height: 22px;
}

.inlinecheckbar .form-check-input:checked[type="radio"] {
  background: white;
}

.inlinecheckbar .form-check-input:checked[type="radio"]:focus {
  /* box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%); */
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .linkedbtn
  button {
  width: 100%;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar {
  margin: 24px 0px;
  position: relative;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar
  p {
  margin: 0 auto;
  max-width: 20%;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: white;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar::after,
.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar::before {
  content: "";
  width: 40%;
  height: 1px;
  background: white;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar::before {
  right: 0;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .or-bar::after {
  left: 0;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .auth-form {
  border: 1px solid white;
  border-radius: 20px;
  padding: 60px;
  position: relative;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .auth-form
  button.btn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: -28px;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .form-check-input:checked {
  border: 1px solid #ffffff !important;
  background: #ffffff !important;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .form-check-input {
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.Auth-bar
  .authbar
  .container
  .authbar-inner
  .rightbar
  .right-contentbar
  .checkboxbar {
  margin-bottom: 30px;
}

.inlinecheckbar .form-check {
  margin-right: 34px;
  margin-bottom: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.inlinecheckbar .form-check.mr-0 {
  margin-right: 0;
}
.terms-bar a {
  color: white;
  font-weight: 400;
  text-decoration: underline;
}

.signup-text a {
  color: white;
  text-decoration: underline;
}

.signup-text {
  color: white;
  font-size: 16px;
  font-weight: inherit;
  font-family: inherit;
}

.sign-wapper {
  width: 362px;
  height: 111px;
  border: 1px solid white;
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-wapper a {
  font-size: 18px;
  color: white;
  margin-top: 13px;
  margin-bottom: 0;
  font-weight: inherit;
  font-family: inherit;
}

.wrapper-score {
  width: 50%;
}

.login-bottom {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
}

.none-under {
  text-decoration: none !important;
}

.loginform.otp-Verification {
  display: flex;
  justify-content: space-between;
}

.loginform.otp-Verification input {
  width: 54px;
  height: 62px;
  border: 1px solid white;
  border-radius: 14px;
  font-weight: 400;
  font-size: 24px;
  padding-bottom: 0;
}

.resend-text p {
  color: white;
  font-size: 18px;
  font-weight: inherit;
  font-family: inherit;
}

.resend-text a {
  color: white;
}

.info-wapper h5 {
  font-family: "Pangram";
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}

.info-wapper p {
  font-family: "Pangram";
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0px;
  color: grey;
}

.Personal-Information-content input {
  background: #ffffff;
  border: 1px solid #c4c4c4 !important;
  border-radius: 12px;
  color: #121212 !important;
  padding-bottom: 0 !important;
  height: 56px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 16px;
}

.Personal-Information-content input::placeholder {
  color: #cacaca !important;
  font-size: 14px;
}

.Personal-Information-content label {
  font-weight: 400;
  font-size: 12px;
  color: #121212;
  margin-bottom: 10px;
}

.left-info-wapper a {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #212121 !important;
  padding: 0;
  background: transparent !important;
}

.left-info-wapper .nav-item {
  background: transparent;
  color: #de4724 !important;
}

/* a:hover {
  color: #000000 !important;
} */

.left-info-wapper .nav-item {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.active-icon {
  width: 40px;
  height: 40px;
  background: #de4724;
  border-radius: 50px;
  text-align: center;
  line-height: 37px;
  margin-left: 10px;
}

.right-space-wapper {
  padding-left: 60px;
  border-left: 1px solid #dddddd;
}

.Button-section button {
  width: 200px;
  height: 52px;
  background: #de4724;
  border-radius: 12px;
  color: white;
  float: right;
  margin-top: 30px;
}

.profile-block {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
}

.row .Button-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.left-info-wapper {
  padding-right: 60px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

.left-info-wapper .nav-item .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
}

.Personal-Information-content select {
  border: 1px solid #c4c4c4 !important;
  border-radius: 12px;
  color: #cacaca !important;
  padding-bottom: 0 !important;
  height: 56px;
  text-align: left;
  padding-left: 15px;
  margin-bottom: 16px;
  font-size: 14px;
}
.share-it-profile:hover {
  color: #000000;
}

button.btn-fill-border {
  width: 200px;
  height: 52px;
  background: white;
  border-radius: 12px;
  color: #ef7833;
  float: right;
  margin-top: 40px;
  margin-right: 20px;
  border: 1px solid #ef7833;
}

button.btn-fill-border:hover {
  background: #ef7833;
  border-radius: 12px;
  color: white;
  border: 0px;
}

.authbar.hide-bg {
  background: #fff;
}

/* bottom-img-code */
.logo-img {
  display: none;
}

.finacial {
  width: 80%;
}

/* spinner-clock-code */
.spinner-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  height: 50vh;
}

.small-logo {
  padding-left: 0px;
}

.icon-logo-user {
  color: white !important;
  padding-right: 20px;
}

.tabs-item-rental {
  background: #ffffff;
  padding: 25px;
  margin-bottom: 25px;
  background: #ffffff;
  border: 0.5px solid #dddddd;
  box-shadow: 0px 4px 34px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: auto;
}
.feedback-star-div .react-stars {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.location-w p {
  position: relative;
}

.infotext svg.infoicon {
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  font-size: 20px;
}
.location-w .infotext {
  position: relative;
}

.Notifications-wapper.opacity-wapper {
  position: relative;
}

.Notifications-wapper.opacity-wapper svg.infoicon {
  top: 20px;
  right: 30px;
}
.input_table_header .form-control:focus {
  border-color: #ced4da;
  box-shadow: none;
}
.Auth-wrapper input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.Auth-wrapper .btn {
  padding: 14px 70px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
.Auth-wrapper .btn-fill {
  background: #a3ec64;
  border: 1px solid white;
  border-radius: 14px;
  box-shadow: none !important;
  color: #000;
}
.Auth-wrapper .btn-fill:hover,
.btn-fill:active {
  background: #ffffff !important;
  color: #000 !important;
  border-color: #000 !important;
}

.Auth-wrapper .btn-fill svg {
  margin-right: 10px;
}

.Auth-wrapper .form-control {
  background: transparent !important;
  padding: 0 0 14px 0px;
  border-radius: 0;
  height: auto;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: none;
  color: #ffffff !important;
  border: none;
  border-bottom: 0.4px solid #ffffff !important;
  margin-bottom: 25px;
}
.Auth-wrapper .form-control:hover,
.form-control:focus,
.form-control:active {
  outline: 0;
  box-shadow: none;
}

.Auth-wrapper .forminput {
  margin-bottom: 30px;
}

.Auth-wrapper .form-control::placeholder {
  color: rgb(255 255 255 / 40%);
}
.Auth-wrapper input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: #ffffff !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.Auth-wrapper a {
  cursor: pointer;
  text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #253837 !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
}

.Auth-wrapper .form-select:focus {
  box-shadow: none;
  border: 1px solid #de4724 !important;
}

.Auth-wrapper select.form-select {
  box-shadow: none;
}

/* .Auth-wrapper input[type="text"]:focus {
  border: 1px solid #de4724;
} */

.Auth-wrapper .auth-form input:-webkit-autofill,
.auth-form input:-webkit-autofill:hover,
.auth-form input:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff !important;
}
.Auth-wrapper p {
  font-weight: 400;
  font-family: "Butler";
  font-size: 18px;
  line-height: 30px;
  color: #de4724;
}

.Auth-wrapper button:focus {
  outline: none;
}

.Auth-wrapper a {
  text-decoration: none;
}

.Auth-wrapper a:hover {
  text-decoration: none;
  color: #0056d6;
}

/* ------------------------ media queries ------------------------ */

@media only screen and (max-width: 1199px) {
  .dashboardsidebar {
    left: -100%;
    z-index: 10;
  }
  .main .dashboardsidebar {
    left: 0;
  }
  .dashboardsidebar .logobar {
    display: none;
  }
  .dashboardsidebar .sidebar-panel {
    margin-top: 105px;
  }
  .mainoutlet {
    margin-left: 0;
    margin-top: 65px;
  }
  .tableoutlet {
    margin-left: 0;
    margin-top: 65px;
  }
  .top_navbar {
    display: block;
  }
  .navbar {
    background: #223635;
  }
  .navbar-toggler:focus {
    border: none !important;
  }
}

@media only screen and (max-width: 594px) {
  .cardcontainer .card {
    margin-bottom: 1rem;
  }
  .header_table {
    flex-direction: column;
  }
  .mainoutlet {
    padding: 25px 25px 18px;
  }
  .tableoutlet {
    padding: 25px 25px 18px;
  }
}

.popover {
  z-index: 9 !important;
}
.bottom_charts .chart-wrapper {
  margin-bottom: 1rem;
}
.property_text {
  padding: 18px 0 24px 0;
}
.property_text h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 8px;
  display: flex;
}
.property_text p {
  opacity: 0.5;
}
/* .main_card_body{
  max-width: 600px;
} */
.card_image_details {
  max-width: 150px;
  height: auto;
  object-fit: cover;
  overflow: hidden;
}
.card_image_details img {
  max-width: 150px;
  border: 4px solid var(--light);
}
.crd_card span {
  font-size: 13px;
  color: var(--dark);
}
.crd_card p {
  opacity: 0.8;
}
.global-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;
}
.admin-chart {
  margin-top: 10px;
}
.landlord-main {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
}
/* .landlord-main .table-responsive {
  flex: 1;
} */
.tenats-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.tenats-main .table-responsive {
  flex: 1;
}
.tenants-filter-box {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.waitlist-main .header_table {
  margin-bottom: 30px;
}
.waitlist-main .header_table h1 {
  margin: 0;
}
.trasaction-main .header_table {
  margin-bottom: 30px;
}
.trasaction-main .header_table h1 {
  margin: 0;
}
.transaction-main .header_table {
  margin-bottom: 30px;
}
.transaction-main .header_table h1 {
  margin: 0;
}
.rented-main .header_table {
  margin-bottom: 30px;
}
.rented-main .header_table h1 {
  margin: 0;
}
.unrented-main .header_table {
  margin-bottom: 30px;
}
.unrented-main .header_table h1 {
  margin: 0;
}
.company-logo-box {
  display: flex;
  align-items: end;
  justify-content: center;
}
.image-box {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 1px solid #ced4da;
}
.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
}
.file-update-btn {
  background-color: #ddd;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 100px;
  margin-inline-start: -16px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: all 0.3s ease-in-out;
}
/* .file-update-btn:hover {
  border-color: #727272;
} */
.file-update-btn input {
  display: none;
}
.company-edit-profile {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.company-name-box input {
  width: 100%;
  height: 56px;
}
.document-update-box input {
  display: none;
}
.document-update-box label {
  cursor: pointer;
}
.document-update-box.form-control {
  height: 56px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.upload-doucmnet-btn {
  border-radius: 8px;
  width: 100%;
  height: 56px;
  background-color: #a3eb64;
  border: 1px solid #a3eb64;
  transition: all 0.3s ease-in-out;
}
.upload-doucmnet-btn:hover {
  background-color: #fff;
  border-color: #a3eb64;
}
.btn {
  padding: 0.972vw 3vw;
  border-radius: 0.833vw;
  font-weight: 500;
  font-size: 1.042vw;
  line-height: 1.667vw;
  text-align: center;
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
}
.lightGreenText {
  color: #a3eb64 !important;
}
.menu-togglebar {
  border-radius: 0.694vw;
  background: #f5f5f5;
  padding: 0.278vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 8.889vw !important;
}

.menu-togglebar label.customradiobtn {
  width: 50%;
  position: relative;
}

.menu-togglebar label.customradiobtn input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.menu-togglebar label.customradiobtn input[type="radio"]:checked ~ .checkmark1 {
  border-radius: 0.417vw !important;
  width: 100%;
  background: var(--Light-Green, #a3eb64);
  color: #000000;
}

.menu-togglebar a.customradiobtn {
  width: 50%;
  text-decoration: none;
}

.phone_number_div .special-label {
  display: none;
}

.menu-togglebar .checkmark1 {
  color: #253837;
  text-align: center;
  font-family: "Google Sans";
  font-size: 0.972vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  height: 1.944vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: transparent;
}

.btngreen {
  background: #a3eb64 !important;
  border-color: #a3eb64 !important;
  color: var(--Dark-green, #253837) !important;
}

.company-name-box {
  width: 38% !important;
}
.doc-main-box {
  width: 63.3% !important;
}
.upload-btn-box {
  width: 36% !important;
}
.uploade-page-doc-main {
  width: 60% !important;
}
.logo-main-box {
  text-align: center;
}
.doc-footer-btn {
  border-radius: 8px;
  height: 56px;
  background-color: #a3eb64;
  border: 1px solid #a3eb64;
  padding-inline: 46px;
  transition: all 0.3s ease-in-out;
}
.doc-footer-btn:hover {
  background-color: #fff;
  border-color: #a3eb64;
}
.document-update-box .file-box {
  background-color: #eee;
  height: 100%;
  display: flex;
  align-items: center;
  padding-inline: 8px;
  border-radius: 0.375rem;
}
.form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
}
.landlorad-detail-page-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit_form_details .company-status-chnage-update-btn {
  width: 20%;
  background-color: #a3eb65 !important;
  border-color: #a3eb65;
  color: #000 !important;
  margin-left: auto;
}
.edit_form_details .company-status-chnage-update-btn:hover {
  background-color: #fff !important;
  border-color: #a3eb65;
}
.model-box-width .modal-content {
  max-width: 76%;
  margin: auto;
}
.model-box-width .edit_form_details form:last-child {
  padding: 0 20px 16px;
}
.otp-main-box {
  display: flex;
  align-items: center;
  gap: 0px;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 10px;
}
.otp-edit-box {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 8px;
  width: 60%;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 24px;
  letter-spacing: 8px;
}
.otp-main-box svg {
  width: 20px;
  height: 20px;
  color: #000;
}
.copy-icon-box {
  background-color: #a3ec64;
  padding: 17px 12px;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.wallet-searchbtn button {
  width: auto !important;
  height: auto !important;
  display: flex;
  align-items: center;
  margin: 0;
  border: 1px solid #eee !important;
  border-radius: 0.375rem;
  padding: 10px 20px;
  gap: 10px;
}
.wallet-searchbtn button p {
  margin: 0;
}
.wallet-searchbtn button svg {
  width: 24px;
  height: 24px;
}
.model-header-box {
  margin-top: 10px;
}
.wallet-searchbtn {
  margin-bottom: 20px;
}
.joined_from_select {
  width: auto;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
}
.btn-save {
  background-color: #a3ec64;
  border-radius: 4px;
  color: #000;
  padding: 10px 20px;
  border-color: #a3ec64;
  box-shadow: none;
  border: 1px solid #a3eb64;
}
