.global-loader .child-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.landloar-tabel-head tr {
  white-space: nowrap;
}
.landloar-tabel-head td {
  white-space: nowrap;
}
.d-flex.lanloard-admin-filter {
  justify-content: space-between !important;
  gap: 40px;
  align-items: end;
  margin-bottom: 20px;
}
.landloard-filter-box {
  display: flex;
  align-items: end;
  gap: 24px;
}
.landloard-filter-box button {
  background-color: #253837 !important;
  opacity: 1 !important;
  border-color: #253837 !important;
}
.landloard-filter-box .dropdown-toggle {
  height: 46px;
}
.landloard-filter-box .dropdown button:disabled {
  opacity: 0.3 !important;
}
.lanloard-admin-filter select {
  width: 100%;
  height: 46px;
  border-radius: 0.375rem !important;
}
.d-flex.lanloard-admin-filter h5 {
  font-size: 16px;
  font-weight: 400;
}
.tenats-list-admin-head tr {
  white-space: nowrap;
}
.tenats-list-admin-head td {
  white-space: nowrap;
}
.d-flex.tenantslist-admin-filter {
  justify-content: space-between !important;
  gap: 40px;
  margin-bottom: 20px;
  align-items: end;
}
.d-flex.tenantslist-admin-filter h5 {
  font-size: 16px;
  font-weight: 400;
}
.tenantslist-admin-filter select {
  width: 100%;
  height: 46px;
  border-radius: 0.375rem !important;
}
.transactionrental-admin-head tr {
  white-space: nowrap;
}
.transactionrental-admin-head td {
  white-space: nowrap;
}
.transactionassigned-admin-head tr {
  white-space: nowrap;
}
.transactionassigned-admin-head td {
  white-space: nowrap;
}
.patentiallanlord-admin-head th {
  padding: 18px 20px !important;
  font-weight: 500;
}
table .patentiallanlord-admin-head th:first-child {
  border-radius: 8px 0 0 8px;
}
table .patentiallanlord-admin-head th:last-child {
  border-radius: 0 8px 8px 0;
}
.lanloard-admin-head-btn {
  font-size: 14px !important;
  padding: 6px 10px !important;
  background-color: #a3eb65 !important;
  border-color: #a3eb65 !important;
  color: #000 !important;
  transition: all 0.3s ease-in-out;
}
.lanloard-admin-head-btn:hover {
  background-color: #fff !important;
  border-color: #a3eb65 !important;
}
.btn.lanloard-admin-head-btn {
  padding: 10px 30px !important;
  font-size: 16px !important;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}
.btn.lanloard-admin-head-btn:hover {
  background-color: #fff !important;
  border-color: #a3eb65 !important;
}
.table-responsive thead {
  position: sticky;
  top: 0%;
  z-index: 9;
}
.pagination {
  margin-block-start: 10px;
}
.vertical-gap-input-box input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 46px;
}
.vertical-gap-input-box button {
  font-size: 14px !important;
  padding: 6px 20px !important;
  background-color: #a3eb65 !important;
  border: 1px solid #a3eb65 !important;
  color: #000 !important;
  transition: all 0.3s ease-in-out;
  height: 46px;
  border-radius: 0.375rem;
}
.vertical-gap-input-box button:hover {
  background-color: #fff !important;
  border-color: #a3eb65 !important;
}
.landlord-deatail-tabel-main {
  border: 1px solid #eee;
  border-radius: 10px;
  padding-block: 14px;
}
.landlord-deatail-tabel-main ul {
  margin: 0;
}
.landlord-deatail-tabel-main ul li {
  list-style: none;
  display: flex;
  align-items: start;
  gap: 10px;
  margin-bottom: 10px;
}
.landlord-deatail-tabel-main p {
  margin: 0;
}
.landlord-deatail-tabel-main h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.tabel-companey-logo {
  text-align: center;
}
.tabel-companey-logo h6 {
  margin-bottom: 10px;
}
.tabel-companey-logo .tabel-logo-image {
  width: 300px;
  height: 190px;
  object-fit: contain;
  border: 1px solid #eee;
  border-radius: 10px;
  margin: auto;
  padding: 5px;
}
.tabel-companey-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.pagination {
  margin: 10px auto;
  display: flex;
  justify-content: center;
}
.pagination .page-item a {
  color: #000;
}
.pagination .page-item.active a {
  background-color: #a3eb65;
  color: #000;
  border-color: #a3eb65;
}
.pagination .page-item.active a:focus {
  box-shadow: none;
}
